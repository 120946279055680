import { Grid, H2, P1, Section } from "@mailbrew/uikit";
import { useSelector } from "react-redux";
import { authUserSelector } from "../reducers/authReducer";
import Page from "./Page";
import StyledA from "./StyledA";
import StyledLink from "./StyledLink";

const ErrorPage = ({ title, body }) => {
  const user = useSelector(authUserSelector);

  return (
    <Page title="Not found :(">
      <Section center>
        <H2 align="center" mb={2}>
          {title ?? "Oops..."}
        </H2>
        <P1 align="center" mb={6}>
          {body ?? "Can't find what you're looking for."}
        </P1>
        {user ? (
          <StyledLink icon="homeBold" variant="secondary" to={"/"}>
            Go Home
          </StyledLink>
        ) : (
          <Grid gap="0.4em">
            <StyledA icon="homeBold" variant="secondary" href="https://mailbrew.com">
              Home
            </StyledA>
            <StyledLink icon="login" variant="secondary" to={"/"}>
              Login
            </StyledLink>
          </Grid>
        )}
      </Section>
    </Page>
  );
};

export default ErrorPage;
