/* eslint-disable jsx-a11y/accessible-emoji */
import {
  Button,
  Card,
  Center,
  H3,
  H4,
  P1,
  P2,
  Section,
  Stack,
  SubtleCard,
  useBreakpoint,
  useConfig,
} from "@mailbrew/uikit";
import Axios from "axios";
import ErrorPage from "components/ErrorPage";
import ExternalLink from "components/ExternalLink";
import FadeInStack from "components/FadeInStack";
import InfoButton from "components/InfoButton";
import ProfileImageWithUpload from "components/ProfileImageWithUpload";
import SEO from "components/SEO";
import StyledA from "components/StyledA";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import Linkify from "react-linkify";
import { useDispatch, useSelector } from "react-redux";
import { apiBaseURL } from "utils/env";
import BrewPublicCard from "../../components/BrewPublicCard";
import BrewsGrid from "../../components/BrewsGrid";
import EditBioModal from "../../components/EditBioModal";
import Page from "../../components/Page";
import { PillButton } from "../../components/PillButton";
import UserAvatar from "../../components/UserAvatar";
import useTrackPageView from "../../hooks/useTrackPageView";
import { authUserSelector } from "../../reducers/authReducer";
import urls from "../../urls";
import createMailtoLink from "../../utils/createMailtoLink";

const ProfilePage = ({ user, publicNewsletters, error: ssrError }) => {
  const router = useRouter();
  const username = router.query.username;

  const dispatch = useDispatch();
  const config = useConfig();
  const isMobile = useBreakpoint(480);

  const [editBioModalShown, setEditBioModalShown] = useState(false);
  const loggedUser = useSelector(authUserSelector);
  const selfProfile = username === loggedUser?.username;

  useTrackPageView("Profile Page", user && { user_id: user.id });
  useSetSignupSource("public_brew");

  // handle case in which you are logged in but your profile is private
  if (!user) {
    user = loggedUser;
    publicNewsletters = [];
  }

  const noPublicNewsletters = publicNewsletters?.length === 0;
  const privateProfile = noPublicNewsletters && !selfProfile;

  // handle SSR error when not logged in
  if (ssrError && !user) {
    return <ErrorPage />;
  }

  if (!user) {
    return null;
  }

  if (privateProfile) {
    return (
      <Page showMonetizationBanner title="You seem lost">
        <Section center>
          <FadeInStack>
            <span style={{ fontSize: "40px" }}>😕</span>
            <H4 mt={3} mb={1}>
              Can't find what you're looking for...
            </H4>
            <P1>
              You can <Link href={urls.login()}>login here</Link>, or check out{" "}
              <ExternalLink href="https://mailbrew.com">our landing page</ExternalLink>.
            </P1>
          </FadeInStack>
        </Section>
      </Page>
    );
  }

  return (
    <Page showMonetizationBanner title={user.full_name}>
      <SEO title={user.full_name} description={user.bio} ogImage={user.card_url} />
      <Section first>
        <Center>
          <Stack vertical align="center" gap={1} mb={isMobile ? 6 : 8}>
            {selfProfile ? <ProfileImageWithUpload size={74} /> : <UserAvatar user={user} size="74px" />}
            <H3 mt={2} color={config.colors.c1} align="center">
              {user.full_name}
            </H3>
            <a href={urls.profile(user.username)} target="_blank" rel="noopener noreferrer">
              <P1 color={config.colors.c1}>@{user.username}</P1>
            </a>
            <UserBio
              user={user}
              selfProfile={selfProfile}
              loggedUser={loggedUser}
              setEditBioModalShown={setEditBioModalShown}
            />
          </Stack>
          {!noPublicNewsletters && selfProfile && (
            <SubtleCard py={2} px={3} mb={8}>
              <P2 align="center">
                This page is public{" "}
                <InfoButton text="You profile is public because you have at least one public brew." />
              </P2>
            </SubtleCard>
          )}
        </Center>
        {publicNewsletters && publicNewsletters.length > 0 && renderNewsletters(publicNewsletters, dispatch, config)}
      </Section>
      {!selfProfile && (
        <Section center style={{ flex: "0 0 auto" }}>
          <ReportUserButton user={user} />
        </Section>
      )}
      {noPublicNewsletters && (
        <Section center style={{ paddingTop: 0 }}>
          {selfProfile ? (
            <Card px={9} py={7} maxW="500px" mb="18vh" flex ai="center" fd="column">
              <H4 align="center" mb={2}>
                Your profile page is still private 🔒
              </H4>
              <P1 align="center">
                Publish your brews by clicking their{" "}
                <PillButton icon="open" style={{ pointerEvents: "none" }}>
                  Publish
                </PillButton>{" "}
                button, and this profile page will become publicly accessible.
              </P1>
            </Card>
          ) : (
            <P1 color={config.colors.c4}>{user.first_name || "This user"} has no public brews&nbsp;&nbsp;👀</P1>
          )}
        </Section>
      )}
      <EditBioModal show={editBioModalShown} setShow={setEditBioModalShown} />
    </Page>
  );
};

const UserBio = ({ user, selfProfile, loggedUser, setEditBioModalShown }) => {
  if (selfProfile) {
    if (loggedUser.profile.bio?.length) {
      return (
        <>
          <P2 align="center" style={{ maxWidth: "34em", width: "90%", whiteSpace: "pre-wrap", marginBottom: "0.3em" }}>
            <Linkify>{loggedUser.profile.bio}</Linkify>&nbsp;
          </P2>
          <BioButton icon="writeBold" onClick={() => setEditBioModalShown(true)}>
            Edit bio
          </BioButton>
        </>
      );
    } else {
      return (
        <BioButton icon="writeBold" onClick={() => setEditBioModalShown(true)}>
          Add a small bio
        </BioButton>
      );
    }
  } else {
    return (
      user.profile?.bio && (
        <P2 align="center" style={{ maxWidth: "34em", width: "90%", whiteSpace: "pre-wrap" }}>
          <Linkify>{user.profile.bio}</Linkify>
        </P2>
      )
    );
  }
};

const BioButton = ({ children, icon, onClick }) => {
  const config = useConfig();
  return (
    <Button mt={1} icon={icon} color={config.colors.c2} variant={["pill", "secondary"]} onClick={onClick}>
      {children}
    </Button>
  );
};

const ReportUserButton = ({ user }) => {
  const link = createMailtoLink({
    to: "support@mailbrew.com",
    subject: `Mailbrew user report: ${user?.full_name}`,
    body: `Dear Mailbrew team,
    
    I want to report ${user?.full_name} because... (please provide a detailed reason).

    Reported user profile page: https://app.mailbrew.com${urls.profile(user?.username)}
    `,
  });
  return (
    <StyledA href={link} variant={["white", "pill"]} icon="flagBold">
      Report User
    </StyledA>
  );
};

function renderNewsletters(newsletters) {
  if (newsletters.length === 1) {
    return (
      <Center>
        {newsletters.map((n) => {
          return <BrewPublicCard key={n.id} newsletter={n} maxWidth="34em" />;
        })}
      </Center>
    );
  } else {
    return (
      <BrewsGrid>
        {newsletters.map((n) => {
          return <BrewPublicCard key={n.id} newsletter={n} />;
        })}
      </BrewsGrid>
    );
  }
}

export function useSetSignupSource(signupSourceId) {
  const user = useSelector(authUserSelector);
  useEffect(() => {
    if (!user) {
      // set this only for logged out users
      localStorage && localStorage.setItem("mailbrew_signup_source", signupSourceId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id]);
}

export async function getServerSideProps(context) {
  try {
    const username = context.params.username;
    const userRequest = Axios.get(`${apiBaseURL}/user_public/?username=${username}`);
    const newslettersRequest = Axios.get(`${apiBaseURL}/newsletters/?user=${username}`);
    const res = await Promise.all([userRequest, newslettersRequest]);
    return { props: { user: res[0].data, publicNewsletters: res[1].data } };
  } catch (error) {
    return { props: { error: error.response.data.detail } };
  }
}

export default ProfilePage;
