/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Box, Card, CardFooter, H3, HStack, P1, P2, useConfig, VStack } from "@mailbrew/uikit";
import Link from "next/link";
import { Fragment, useMemo } from "react";
import { useSelector } from "react-redux";
import { authUserSelector } from "../reducers/authReducer";
import urls from "../urls";
import humanFriendlySchedule from "../utils/humanFriendlySchedule";
import BrewManageOptionsDropdown from "./BrewManageOptionsDropdown";
import BrewSubscribersBadge from "./BrewSubscribersBadge";
import { EditButton } from "./BrewView";
import InlineUser from "./InlineUser";
import SourcesBadges from "./SourcesBadges";
import StyledLink from "./StyledLink";
import UnstyledA from "./UnstyledA";

const BrewPublicCard = (props) => {
  const {
    newsletter,
    newsletter: { title, untitled, sources, schedule, author },
    timezone,
    style,
    hasUser = true,
    inline = true,
  } = props;

  const config = useConfig();

  const user = useSelector(authUserSelector);
  const isSelf = useMemo(() => author?.id === user?.id, [author, user]);

  return (
    <Card width="100%" maxWidth={props.maxWidth} inline={inline} flex fd="column" style={style}>
      <HStack align="spaced" vAlign="top" style={{ flex: "1 0 auto", flexWrap: "nowrap" }}>
        <VStack style={{ alignSelf: "stretch" }} gap={hasUser ? 1 : 0.7}>
          {hasUser && <InlineUser user={author} />}
          <Link href={urls.viewNewsletter(newsletter.share_url)}>
            <UnstyledA>
              <H3 color={!untitled ? config.colors.c1 : config.colors.c5} style={{ cursor: "pointer" }}>
                {title}
              </H3>
            </UnstyledA>
          </Link>
          {newsletter.description?.length > 0 && <P1 mb={2}>{newsletter.description}</P1>}
          <Box my={0} style={{ flex: "1 1 auto" }} />
          {sources?.length > 0 && <SourcesBadges size={24} sources={sources} />}
          <P2 color={config.colors.c4} mt={2} noWrap overflow="hidden">
            {humanFriendlySchedule(schedule)}
            {timezone ? ` (${timezone})` : ""}
          </P2>
        </VStack>
        <HStack align="right" noWrap>
          {/* show options only to logged in users != self */}
          {!isSelf && user && <BrewManageOptionsDropdown newsletter={newsletter} author={author} />}
        </HStack>
      </HStack>

      <CardFooter inline style={{ flexGrow: 0 }}>
        <HStack align="spaced" noWrap w="100%">
          <BrewSubscribersBadge newsletter={newsletter} withPlaceholder />
          <HStack noWrap align="right">
            <StyledLink
              icon="text"
              noIconAt="370"
              variant="white"
              to={urls.viewNewsletter(newsletter.share_url)}
              noStretch
            >
              Read
            </StyledLink>
            {(isSelf || user) && <Fragment>{isSelf && <EditButton newsletter={newsletter} />}</Fragment>}
          </HStack>
        </HStack>
      </CardFooter>
    </Card>
  );
};

export default BrewPublicCard;
