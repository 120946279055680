import { Box, Card, Icon, P1, Stack, Tooltip, useConfig, useDetectBrowser } from "@mailbrew/uikit";
import Gradient from "components/Gradient";
import { ProBadge } from "components/Paywall";
import { sourcesData } from "data/sourcesData";
import { motion } from "framer-motion";
import useDarkModeListener from "hooks/useDarkModeListener";
import useShowSourceProBadge from "hooks/useShowSourceProBadge";
import { useEffect, useMemo, useState } from "react";
import tinycolor from "tinycolor2";
import { BetaBadge } from "./PatronBetaBadge";

const AddSourceBox = ({ title: providedTitle, sourceType, onClick, info: providedInfo, small, checked, disabled }) => {
  const config = useConfig();
  const { isIos } = useDetectBrowser();

  const source = sourcesData[sourceType] ?? {};
  let name = providedTitle ?? source.name;
  let { shortName, additionalInfo, color, beta, glyph: SourceIcon } = source;

  additionalInfo = providedInfo ?? additionalInfo;

  const showProBadge = useShowSourceProBadge(sourceType);

  const { isDark } = useDarkModeListener();
  const sourceColor = useSourceColorAdjustedForDarkMode(sourceType);

  const shadowColor = useMemo(() => tinycolor(color).darken(10).setAlpha(0.03).toString(), [color]);

  const [hovering, setHovering] = useState(false);
  const hoverProps =
    isIos || !onClick
      ? {}
      : {
          onMouseEnter: () => setHovering(true),
          onMouseLeave: () => setHovering(false),
        };

  return (
    <Card
      {...hoverProps}
      padding="13px 4px 8px"
      inline
      shadow={isDark ? "none" : undefined}
      onClick={() => !disabled && onClick && onClick()}
      style={{
        overflow: "visible",
        cursor: onClick ? "pointer" : "auto",
        transition: "border 0.05s",
        boxShadow: `0px 1px 5px ${shadowColor}`,
        ...(hovering ? { border: `1px solid ${color}` } : {}),
      }}
    >
      <Gradient
        color={color}
        rotate={180}
        startAlpha={0.05}
        endAlpha={0.01}
        end={0.6}
        style={{ borderRadius: config.Card.smallRadius }}
      />
      {checked && <IconChecked />}
      {additionalInfo && !checked && <SourceInfo content={additionalInfo} />}
      {showProBadge && (
        <ProBadge radius="6px" zoom="0.7" style={{ position: "absolute", top: "9px", left: "9px" }} color={color} />
      )}
      {beta && <BetaBadge size={0.7} style={{ position: "absolute", top: "7px", right: "7px" }} />}
      <Stack vertical align="center" gap={1}>
        <Box
          style={{
            background: color,
            width: "32px",
            height: "32px",
            borderRadius: "32px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <SourceIcon style={{ width: "26px", height: "26px" }} color="white" />
        </Box>
        <P1 fontSize={small ? "13px" : "14.5px"} align="center" noWrap color={sourceColor} weight="500">
          {providedTitle ? name ?? shortName : shortName ?? name}
        </P1>
      </Stack>
    </Card>
  );
};

const SourceInfo = ({ content }) => {
  const config = useConfig();
  return (
    <div style={{ position: "absolute", top: "6px", right: "6px" }}>
      <Tooltip title={content} innerStyle={{ textAlign: "left" }} maxWidth={240} position="top">
        <Icon color={config.colors.c5} name="info" />
      </Tooltip>
    </div>
  );
};

const IconChecked = () => {
  const config = useConfig();
  return (
    <motion.div
      initial={{ scale: 0, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      style={{ position: "absolute", top: "5px", right: "5px" }}
    >
      <Icon size={20} color={config.colors.success} name="checkmarkCircle" />
    </motion.div>
  );
};

export function useSourceColorAdjustedForDarkMode(id) {
  const sourceColor = sourcesData[id]?.color;
  const [sourceColorOverride, setSourceColorOverride] = useState(sourceColor);

  const { isDark } = useDarkModeListener({
    onChange: (isDark) => {
      tweakColorForDarkMode(sourceColor, isDark, setSourceColorOverride);
    },
  });

  useEffect(() => {
    tweakColorForDarkMode(sourceColor, isDark, setSourceColorOverride);
  }, [isDark, sourceColor]);

  return sourceColorOverride;
}

function tweakColorForDarkMode(color, isDark, setSourceColorOverride) {
  if (isDark && tinycolor(color).getLuminance() < 0.1) {
    setSourceColorOverride("white");
  } else if (isDark && tinycolor(color).getLuminance() < 0.3) {
    setSourceColorOverride(tinycolor(color).brighten(30).toString());
  } else {
    setSourceColorOverride(color);
  }
}

export default AddSourceBox;
