import { Grid, H3, Modal, P1, useBreakpoint } from "@mailbrew/uikit";
import { useDispatch, useSelector } from "react-redux";
import signUpLink from "utils/signUpLink";
import { copySourceToNewsletter } from "../reducers/newslettersReducer";
import SourceBox from "./SourceBox";

const SourcesModal = (props) => {
  const { show, setShow, newsletter, userNewsletters } = props;
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  const breakpointHit = useBreakpoint();

  const filteredSources = newsletter.sources.filter((s) => !excludeList.includes(s.type));

  return (
    <Modal width="40em" show={show} setShow={setShow} bottomSheet={breakpointHit}>
      <H3 mb={2}>Sources</H3>
      <P1 mb={!user ? 1 : 5}>This digest is compiled from the following sources</P1>
      {!user && (
        <P1 mb={5}>
          <a href={signUpLink("sources-modal")}>Sign up to Mailbrew</a> to create your own digest.
        </P1>
      )}
      {filteredSources && (
        <>
          <Grid columns="1fr 1fr" gap="0.8em" breakAt="640px">
            {filteredSources.map((source, i) => (
              <SourceBox
                key={i}
                source={source}
                newsletter={newsletter}
                userNewsletters={userNewsletters}
                onNewsletterSelected={(newsletterID) => dispatch(copySourceToNewsletter(newsletterID, source))}
              />
            ))}
          </Grid>
        </>
      )}
    </Modal>
  );
};

const excludeList = ["today", "text", "separator"];

export default SourcesModal;
