import { Box, HStack, P1, UIKitCSSProperties, useConfig } from "@mailbrew/uikit";
import React from "react";
import UserAvatar from "./UserAvatar";

const InlineUser = ({ user, color, fontSize, avatarSize = "24px", href, noLink }) => {
  const { full_name, username } = user || {};

  const config = useConfig();

  const wrapperStyle: UIKitCSSProperties = {
    width: "auto",
    display: "inline-block",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    flex: "0 1 auto",
  };

  const userUrl = href ?? `/${username}`;

  if (user) {
    return (
      // @ts-ignore
      <Box as={!noLink && userUrl ? "a" : "div"} style={wrapperStyle} href={userUrl}>
        <HStack noWrap gap={1.6}>
          <UserAvatar user={user} size={avatarSize} />
          <P1 fontSize={fontSize} color={color || config.colors.c2} style={{ fontWeight: 500 }}>
            {full_name}
          </P1>
        </HStack>
      </Box>
    );
  } else {
    return (
      // @ts-ignore
      <Box as={!noLink && userUrl ? "a" : "div"} style={wrapperStyle} href={userUrl}>
        <HStack noWrap gap={1.6}>
          <UserAvatar user={null} size={avatarSize} />
          <P1 fontSize={fontSize}>Loading...</P1>
        </HStack>
      </Box>
    );
  }
};

export default InlineUser;
