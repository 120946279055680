/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { Divider, DropDownMenu, Grid, Icon, P2, useConfig } from "@mailbrew/uikit";
import { useSourceColorAdjustedForDarkMode } from "components/editor/AddSourceBox";
import { editColor } from "components/editor/ColorPicker";
import { sourcesData } from "../data/sourcesData";
import { getSourceTitle, getSourceURL } from "../utils/sources";

const SourceBox = (props) => {
  const { newsletter, userNewsletters, source, onNewsletterSelected } = props;

  const sourceLink = getSourceURL(source);

  const s = sourcesData[source.type];

  const config = useConfig();

  const { smallRadius } = config.appearance;

  const sourceColor = useSourceColorAdjustedForDarkMode(source.type);
  const bgColor = editColor(sourceColor, { alpha: 0.08 });

  const wrapperCSS = css`
    padding: 0.4em 0.7em 0.4em 0.5em;
    border-radius: ${smallRadius};
    background-color: ${bgColor};
    flex: none;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    height: 2.8em;
    cursor: auto;
    svg.source-icon {
      flex: 0 0 0;
      width: 25px;
      height: 25px;
      path,
      rect {
        fill: ${sourceColor};
      }
    }
    span {
      color: ${sourceColor};
      font-weight: 500;
      font-size: 15px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  `;

  const SourceIcon = s.glyph;

  const hasNewsletters = userNewsletters && userNewsletters.length > 1;

  const newslettersFiltered = userNewsletters.filter((n) => n.id !== newsletter.id);

  const options = newslettersFiltered ? newslettersFiltered.map((n) => n.id) : [];
  const optionsNames = newslettersFiltered ? newslettersFiltered.map((n) => n.title) : [];

  return (
    <div css={wrapperCSS}>
      <Grid
        columns={`27px 1fr ${hasNewsletters ? "1.3em" : ""} ${sourceLink ? "1.3em" : ""}`}
        gap="4px"
        width="100%"
        vAlign="center"
      >
        <SourceIcon className="source-icon" />
        <span>{getSourceTitle(source)}</span>
        {sourceLink && (
          <a href={sourceLink} target="_blank" rel="noopener noreferrer">
            <Icon size="16px" color={sourceColor} name="open" />
          </a>
        )}
        {hasNewsletters && (
          <DropDownMenu
            header={
              <div>
                <P2 style={{ margin: "0 0 .2em" }} color={config.colors.c4}>
                  Copy to brew
                </P2>
                <Divider width="100%" size="xxs" />
              </div>
            }
            options={options}
            optionsNames={optionsNames}
            onSelect={(option) => onNewsletterSelected(option)}
            scrollable={true}
          >
            <Icon size="16px" color={sourceColor} name="chevronDown" />
          </DropDownMenu>
        )}
      </Grid>
    </div>
  );
};

export default SourceBox;
