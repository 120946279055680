import {
  Box,
  Button,
  Card,
  Grid,
  H2,
  H4,
  HStack,
  Input,
  notif,
  P1,
  P2,
  Section,
  Stack,
  UIKitCSSProperties,
  useBreakpoint,
  useConfig,
} from "@mailbrew/uikit";
import { useFormik } from "formik";
import { useRouter } from "next/router";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import useSWR from "swr";
import apiErrMsg from "utils/apiErrMsg";
import { fromNowOrDate } from "utils/formatDate";
import * as yup from "yup";
import api, { axiosSwrFetcher } from "../dependencies/api";
import useCloneNewsletter from "../hooks/useCloneNewsletter";
import useShowNotificationForApiError from "../hooks/useShowNotificationForApiError";
import { authUserSelector } from "../reducers/authReducer";
import urls from "../urls";
import humanFriendlySchedule from "../utils/humanFriendlySchedule";
import kFormatter from "../utils/kFormatter";
import { pluralize } from "../utils/pluralize";
import BrewIssueViewer from "./BrewIssueViewer";
import InlineUser from "./InlineUser";
import IssuePicker from "./IssuePicker";
import OwnerBrewIssueViewer from "./OwnerBrewIssueViewer";
import Pages from "./Pages";
import PublishBrewButton from "./PublishBrewButton";
import SourcesModal from "./SourcesModal";
import StyledTooltip from "./StyledTooltip";

interface BrewViewProps {
  shareId: string;
  issueNumber?: number;
}

const BrewView = (props: BrewViewProps) => {
  const { shareId, issueNumber } = props;
  const user = useSelector(authUserSelector);

  const [currentIssueNumber, setCurrentIssueNumber] = useState(issueNumber);
  const [currentIssue, setCurrentIssue] = useState(null);

  const { newsletter, issues, author, isSelf } = usePublicBrewInfo(shareId);
  const isPublic = newsletter?.public;

  // fetch issues
  useEffect(() => {
    if (issues && issues.length > 0) {
      if (currentIssueNumber) {
        setCurrentIssue(issues.find((issue) => issue.serial_number === currentIssueNumber));
      } else {
        // when no issue number is provided in the url, show the latest issue (top of the list of issues)
        setCurrentIssueNumber(issues[0].serial_number);
      }
    } else {
      setCurrentIssue(null);
    }
  }, [currentIssueNumber, issues]);

  if (!newsletter || !issues || !author) {
    return null;
  }

  return (
    <Section width="100%" noPadding noLateralPadding>
      {(isPublic || (user && !isSelf)) && (
        <NewsletterHeader newsletter={newsletter} isSelf={isSelf} author={author} showActions={showActions} />
      )}
      <BrewPreviewSection
        newsletter={newsletter}
        issues={issues}
        currentIssueNumber={currentIssueNumber}
        setCurrentIssueNumber={setCurrentIssueNumber}
        currentIssue={currentIssue}
        actions={
          showActions && (
            <HStack
              mb={isSelf ? 4 : 0}
              gap={2}
              align="right"
              vAlign="center"
              flexWrap="nowrap"
              breakAt={user && isSelf ? 640 : 0}
              breakAlign="right"
              w="674px"
              maxW="100%"
              mx="auto"
            >
              <UserActions newsletter={newsletter} author={author} isSelf={isSelf} />
              <IssuePicker
                currentIssueNumber={currentIssueNumber}
                setCurrentIssueNumber={setCurrentIssueNumber}
                issues={issues}
                newsletter={newsletter}
              />
            </HStack>
          )
        }
        hidePreviewNotice={hidePreviewNotice}
        hideHeaderAndFooter={!(user && isSelf) || isPublic}
        forceLivePreview={forceLivePreview}
        fullWidthCard={fullWidthCard}
      />
    </Section>
  );
};

const UserActions = (props) => {
  const { newsletter, isSelf } = props;
  const user = useSelector(authUserSelector);

  const hasSources = !!newsletter?.sources?.length;

  return (
    <HStack gap={2.5} noWrap maxW="100%">
      <HStack noWrap maxW="100%">
        {user && isSelf && <EditButton newsletter={newsletter} />}
        {user && !isSelf && <CloneButton newsletter={newsletter} />}
        {hasSources && <ViewSourcesButton newsletter={newsletter} />}
        {user && isSelf && <PublishBrewButton newsletter={newsletter} />}
      </HStack>
    </HStack>
  );
};

const NewsletterHeader = (props) => {
  const { newsletter, isSelf, author } = props;

  const config = useConfig();

  const breakPoint = 820;
  const breakpointHit = useBreakpoint(breakPoint);

  const user = useSelector(authUserSelector);

  return (
    <Section width="920px" style={{ zIndex: 1 }} first>
      <Grid
        columns={"1fr auto"}
        mb={6}
        mt={breakpointHit ? 0 : user ? 2 : 7}
        gap="2em"
        breakAt={breakPoint}
        vAlign="center"
      >
        <HStack align="spaced" breakAt={breakPoint} breakAlign="center" vAlign="top" style={{ flexWrap: "nowrap" }}>
          <div style={{ flex: "1 1 0" }}>
            <Stack align={breakpointHit ? "center" : "left"}>
              {/* @ts-ignore */}
              <InlineUser user={author} avatarSize="26px" fontSize="17.5px" />
            </Stack>
            <H2 as="h1" mt={2} mb={2} centerAt={breakPoint}>
              {newsletter.title}
            </H2>
            {newsletter.description && (
              <P1 color={config.colors.c2} centerAt={breakPoint} maxW={120} mb={3}>
                {newsletter.description}
              </P1>
            )}
            <P2 centerAt={breakPoint} mt={2} mb={1}>
              {newsletter.subscribers_count > 0 && (
                <Fragment>
                  {kFormatter(newsletter.subscribers_count)} {pluralize("subscriber", newsletter.subscribers_count)}{" "}
                  •&nbsp;
                </Fragment>
              )}
              {humanFriendlySchedule(newsletter.schedule)}
            </P2>
          </div>
        </HStack>
        {/* @ts-ignore */}
        <SignUpForm forOwner={isSelf} newsletter={newsletter} breakPoint={breakPoint} />
      </Grid>
    </Section>
  );
};

export const ViewSourcesButton = ({ newsletter, ...rest }) => {
  const [sourcesModalShown, setSourcesModalShown] = useState(false);
  const user = useSelector(authUserSelector);
  const { data: userNewsletters = [] } = useSWR(user && "/newsletters/");

  return (
    <Fragment>
      <Button icon="gridBold" variant="white" onClick={() => setSourcesModalShown(true)} {...rest}>
        Sources
      </Button>
      <SourcesModal
        show={sourcesModalShown}
        setShow={setSourcesModalShown}
        newsletter={newsletter}
        userNewsletters={userNewsletters}
      />
    </Fragment>
  );
};

export const EditButton = ({ newsletter, ...rest }) => {
  const router = useRouter();

  return (
    <Fragment>
      <Button
        variant="white"
        icon={"writeBold"}
        onClick={() => router.push(urls.editNewsletter(newsletter.id))}
        {...rest}
      >
        Edit
      </Button>
    </Fragment>
  );
};

const CloneButton = ({ newsletter }) => {
  const { cloneNewsletter, cloning, cloneError } = useCloneNewsletter();
  useShowNotificationForApiError(cloneError, "Can't clone the newsletter");

  return (
    <StyledTooltip delay={500} title="Create your own copy of this brew and customize it.">
      <Button
        variant="white"
        icon={"copy"}
        loading={cloning}
        disabled={cloning}
        onClick={() => cloneNewsletter(newsletter.id)}
      >
        Clone
      </Button>
    </StyledTooltip>
  );
};

const SignUpForm = ({ newsletter, footer, breakPoint, forOwner }) => {
  const config = useConfig();

  const [submitError, setSubmitError] = useState(null);

  const [SUBSCRIBE, CONFIRM_EMAIL] = [1, 2, 3];
  const [step, setStep] = useState(SUBSCRIBE);

  const [successMessage, setSuccessMessage] = useState(null);

  const handleSubmit = async (values) => {
    setSubmitError(null);
    setSuccessMessage(null);

    try {
      const res = await api.post(`/brew_public_subscribe/`, { email: values.email, newsletter: newsletter.id }); // prettier-ignore

      if (res.data.sent_email_confirmation) {
        setStep(CONFIRM_EMAIL);
      } else {
        setSuccessMessage("Subscribed!");
      }
    } catch (err) {
      setSubmitError(err?.response.data);
    }
  };

  /** Effects */
  const formik = useFormik({
    initialValues: { email: "" },
    validationSchema: yup.object().shape({
      email: yup.string().email().required(), // prettier-ignore
    }),
    onSubmit: handleSubmit,
  });

  /** Computed state */
  const emailFieldErrors = submitError ? submitError.detail : formik.touched.email;

  const Header = ({ children }) => (
    <H4 color={config.colors.accent1} style={{ fontSize: "1.5em", margin: "0 0 0.2em" }}>
      {children}
    </H4>
  );

  const Subtitle = ({ children, style }: { children: any; style?: UIKitCSSProperties }) => (
    <P1 mb={2.5} centerAt={breakPoint} style={style}>
      {children}
    </P1>
  );

  const hit = useBreakpoint(420);

  return (
    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
      <Box w={hit ? "100%" : "380px"}>
        <Pages step={step}>
          <Pages.Page step={SUBSCRIBE}>
            {forOwner ? (
              <Subtitle style={{ fontStyle: "italic", color: config.colors.c2 }}>
                Anyone visiting this page can subscribe.
              </Subtitle>
            ) : (
              <Subtitle>Subscribe here to receive this digest.</Subtitle>
            )}
            <form onSubmit={formik.handleSubmit}>
              <HStack noWrap vAlign="top" gap="3px">
                <Input
                  /* @ts-ignore */
                  name="email"
                  placeholder="Your email"
                  type="email"
                  autoComplete="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={emailFieldErrors}
                  success={successMessage}
                />
                <Button submit noStretch>
                  Subscribe
                </Button>
              </HStack>
            </form>
          </Pages.Page>

          <Pages.Page step={CONFIRM_EMAIL}>
            <Header>One last step...</Header>
            {/* @ts-ignore */}
            <Subtitle>Click on the confirmation email we've sent you to start receiving this brew.</Subtitle>
          </Pages.Page>
        </Pages>
        {footer && footer}
      </Box>
    </div>
  );
};

const BrewPreviewSection = (props) => {
  const {
    actions,
    newsletter,
    currentIssue,
    issues,
    forceLivePreview,
    hidePreviewNotice,
    hideHeaderAndFooter,
    fullWidthCard,
  } = props;

  const config = useConfig();
  const isMobile = useBreakpoint();

  const noIssues = issues?.length === 0;

  return (
    <Section
      noPadding
      width="100%"
      minHeight={`calc( 100vh - ${config.Header.height} - env(safe-area-inset-bottom) )`}
      pt={3}
      pb={10}
      background={config.colors.bg0}
      bt={isMobile ? "none" : `1px solid ${config.colors.uiBorderColor}`}
    >
      {noIssues || forceLivePreview ? (
        <BrewPreviewContainer
          newsletter={newsletter}
          hidePreviewNotice={hidePreviewNotice}
          header={actions}
          hideHeaderAndFooter={hideHeaderAndFooter}
          fullWidthCard={fullWidthCard}
        />
      ) : (
        <BrewIssuesContainer
          header={actions}
          newsletter={newsletter}
          currentIssue={currentIssue}
          fullWidthCard={fullWidthCard}
          hideHeaderAndFooter={hideHeaderAndFooter}
        />
      )}
    </Section>
  );
};

const BrewIssuesContainer = (props) => {
  const { currentIssue, hideHeaderAndFooter, header, newsletter } = props;
  const config = useConfig();
  const { isSelf } = usePublicBrewInfo(newsletter.share_id);

  const ref = useRef(null);

  const { data: currentIssueHtml, error: currentIssueHtmlError } = useSWR(
    // only load when isSelf is false, when it is true the <OwnerBrewIssueViewer /> loads the issue content for itself.
    !isSelf && currentIssue?.content_url,
    axiosSwrFetcher,
    {
      dedupingInterval: 120 * 1000,
    }
  );

  if (currentIssueHtmlError) {
    return <CardWithMessage message="Error fetching issue content." />;
  }

  return (
    <Fragment>
      {header && <Box mb={hideHeaderAndFooter ? 4 : 1}>{header}</Box>}
      {currentIssue && (
        <P2 color={config.colors.c4} textAlign="center" my={3}>
          Sent {fromNowOrDate(currentIssue.publication_date)}.
        </P2>
      )}
      {currentIssue && !currentIssue.content_url && (
        <P1 color={config.colors.c4} align="center" mt={12}>
          Ops, this issue has no content.
        </P1>
      )}
      {isSelf ? (
        currentIssue && <OwnerBrewIssueViewer issue={{ ...currentIssue, newsletter }} noPadding />
      ) : (
        <BrewIssueViewer
          /* @ts-ignore */
          html={currentIssueHtml}
          hideHeaderAndFooter={hideHeaderAndFooter}
          noPadding
          ref={ref}
        />
      )}
    </Fragment>
  );
};

const BrewPreviewContainer = (props) => {
  const {
    newsletter,
    // fullWidthCard,
    hidePreviewNotice,
    hideHeaderAndFooter,
    header,
  } = props;
  const { data: newsletterPreviewHtml, error: newsletterPreviewError } = useSWR(`/newsletter_preview/${newsletter.id}/`); // prettier-ignore

  if (newsletterPreviewError) {
    return <CardWithMessage message="Error loading your brew preview." />;
  }

  return (
    <Fragment>
      {header && <Box mb={hideHeaderAndFooter ? 4 : 1}>{header}</Box>}
      {!hidePreviewNotice && (
        <P2 w="674px" maxW="100%" mx="auto" align="right" mt={4} mb={2}>
          This is a preview. Future issues will be here.
        </P2>
      )}
      {newsletterPreviewHtml && (
        <BrewIssueViewer
          // @ts-ignore
          html={newsletterPreviewHtml}
          hideHeaderAndFooter={hideHeaderAndFooter}
          noPadding
        />
      )}
    </Fragment>
  );
};

const CardWithMessage = (props) => {
  const { message } = props;

  return (
    <Card inline center minHeight={"30em"}>
      <P1>{message}</P1>
    </Card>
  );
};

export function usePublicBrewInfo(newsletterShareId) {
  const user = useSelector(authUserSelector);
  const { data: newsletter } = useSWR(() => `/newsletters_by_share_id/${newsletterShareId}/`);
  const { data: issues } = useSWR(() => `/issues/?newsletter=${newsletter.id}`);

  const author = newsletter?.author;
  const isSelf = author?.username === user?.username;

  return { newsletter, issues, author, isSelf };
}

const showActions = true;
const fullWidthCard = true;
const hidePreviewNotice = false;
const forceLivePreview = false;

export default BrewView;
