import { HStack, Icon, useBreakpoint, useConfig } from "@mailbrew/uikit";
import { useRouter } from "next/router";
import urls from "../urls";

const IssuePicker = (props) => {
  const { currentIssueNumber, setCurrentIssueNumber, issues, newsletter } = props;

  const config = useConfig();
  const firstIssue = currentIssueNumber === 1;
  const lastIssue = issues && issues.length ? currentIssueNumber === issues[0].serial_number : false;
  const breakpointHit = useBreakpoint(640);
  const router = useRouter();

  if (!issues?.length) {
    return null;
  }

  return (
    <HStack
      background={config.colors.bg4}
      radius={breakpointHit ? config.Button.variantProps.small.radius : config.Button.radius}
      height={breakpointHit ? config.Button.variantProps.small.height : config.Button.height}
      px={2}
      gap={0.5}
      noWrap
      style={{ flex: "0 0 auto" }}
    >
      <Icon
        color={!firstIssue ? config.colors.c3 : config.colors.c5}
        name="chevronLeft"
        size="24px"
        style={{ marginRight: "0.3em" }}
        onClick={
          !firstIssue
            ? () => {
                const prevIssue = currentIssueNumber - 1;
                router.push(urls.viewNewsletterIssue(newsletter.share_url, prevIssue));
                setCurrentIssueNumber(prevIssue);
              }
            : undefined
        }
      />
      <Icon
        color={!lastIssue ? config.colors.c3 : config.colors.c5}
        name="chevronRight"
        size="24px"
        onClick={
          !lastIssue
            ? () => {
                const nextIssue = currentIssueNumber + 1;
                router.push(urls.viewNewsletterIssue(newsletter.share_url, nextIssue));
                setCurrentIssueNumber(nextIssue);
              }
            : undefined
        }
      />
    </HStack>
  );
};

export default IssuePicker;
