import { Box } from "@mailbrew/uikit";
import tinycolor from "tinycolor2";

export default function Gradient({ color, style, rotate = 135, startAlpha = 1, endAlpha = 0, start = 0, end = 1 }) {
  const startColor = tinycolor(color).setAlpha(startAlpha).toString();
  const endColor = tinycolor(color).setAlpha(endAlpha).toString();
  return (
    <Box
      style={{
        background: `linear-gradient(${rotate}deg, ${startColor} ${start * 100}%, ${endColor} ${end * 100}%)`,
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        ...style,
      }}
    />
  );
}
