import { Button, H4, HStack, Input, Modal, P2, Spacer, useConfig } from "@mailbrew/uikit";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import useResetError from "../hooks/useResetError";
import { appErrorSelector } from "../reducers/appReducer";
import { authUserSelector, updateBio } from "../reducers/authReducer";

const formSchema = yup.object().shape({
  bio: yup.string().max(256),
});

const EditBioModal = (props) => {
  const { show, setShow, onBioUpdated } = props;

  const dispatch = useDispatch();
  const user = useSelector(authUserSelector);
  const updateBioError = useSelector(appErrorSelector("update_bio"));
  useResetError("update_bio");

  const formik = useFormik({
    initialValues: { bio: user?.profile.bio || "" },
    validationSchema: formSchema,
    onSubmit: async (values) => {
      formik.setSubmitting(true);
      await dispatch(updateBio(user.id, values.bio));
      formik.setSubmitting(false);
      setShow(false);
      onBioUpdated && onBioUpdated();
    },
  });

  return (
    <Modal show={show} setShow={setShow}>
      <H4>Write a small biography here</H4>
      <Spacer size="xxxs" />
      <form onSubmit={formik.handleSubmit}>
        <Input
          placeholder="Billionaire, philanthropist, liar."
          component="textarea"
          height="5em"
          name="bio"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.bio}
          error={updateBioError} // || (formik.touched.bio && formik.errors.bio) -- don't show the formik error as we have the char counter
        />
        <Spacer size="xxs" />
        <CharCounter text={formik.values.bio} limit={256} />
        <HStack align="right">
          <Button width="8em" submit disabled={formik.isSubmitting} loading={formik.isSubmitting}>
            Done
          </Button>
        </HStack>
      </form>
    </Modal>
  );
};

const CharCounter = (props) => {
  const { text, limit } = props;
  const config = useConfig();
  const charsLeft = limit - (text?.length || 0);
  const textColor = charsLeft > 0 ? undefined : config.colors.error;

  return (
    <P2 style={{ color: textColor }}>
      {charsLeft} character{charsLeft !== 1 && "s"} left.
    </P2>
  );
};

export default EditBioModal;
