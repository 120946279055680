import { motion } from "framer-motion";

const { Stack } = require("@mailbrew/uikit");

const FadeInStack = ({
  children,
  scale = 0.9,
  y = 0,
  opacity = 0,
  duration = 0.6,
  bounce = 0.15,
  delay = 0.2,
  ...otherProps
}) => {
  return (
    <Stack
      vertical
      gap={0}
      align="center"
      as={motion.div}
      initial={{ scale: scale, y: y, opacity: opacity }}
      animate={{ scale: 1, y: 0, opacity: 1 }}
      transition={{ type: "spring", duration: 0.6, bounce: 0.15, delay: 0.2 }}
      {...otherProps}
    >
      {children}
    </Stack>
  );
};

export default FadeInStack;
