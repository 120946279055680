/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/core";

const Pages = (props) => {
  const { children, step } = props;

  const wrapperCSS = css`
    > div:not(#step-${step}) {
      display: none;
    }
  `;

  return <div css={wrapperCSS}>{children}</div>;
};

Pages.Page = ({ children, step }) => <div id={`step-${step}`}>{children}</div>;

export default Pages;
